/* You can add global styles to this file, and also import other style files */
@import "./bootstrap-grid.min.css";
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
body {
    max-width: 1920px;
    margin: 0 auto;
    .bada-layout {
        font-family: "Lato", sans-serif;
    }
    .affordable {
        font-family: "Montserrat", sans-serif;
        input,
        nz-input-group {
            background: #ffffff;
            border: none;
        }
        textarea {
            padding: 20px 22px;
        }
        textarea,
        input {
            font-family: "Roboto", sans-serif;
            font-size: 16px;
            line-height: 19px;
            color: rgba(0, 0, 0, 0.6);
        }
    }
    .first-dibs {
        font-family: "Montserrat", sans-serif;
        textarea {
            padding: 20px 22px;
        }
        textarea,
        input {
            font-family: "Roboto", sans-serif;
            font-size: 16px;
            line-height: 19px;
            color: rgba(0, 0, 0, 0.6);
        }
    }
}
.ant-form-item-explain.ant-form-item-explain-error {
  font-size: 12px;
}
